<template>
  <v-container class="pt-4">
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="d-flex align-center rounded-lg pa-2" flat>
          <v-spacer></v-spacer>
          <v-btn tile color="primary" @click="back()" class="rounded-xl">
            {{$t('back')}}
            <v-icon right> mdi-arrow-left </v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="d-flex align-center rounded-lg pa-2 pb-0" flat>
          <DxDataGrid
            :column-hiding-enabled="true"
            id="gridContainer"
            :rtl-enabled="$i18n.locale == 'ar' ? true : false"
            :data-source="degree"
            :show-column-lines="showColumnLines"
            :show-row-lines="showRowLines"
            :show-borders="showBorders"
            :row-alternation-enabled="rowAlternationEnabled"
            :remote-operations="true"
            :repaint-changes-only="true"
            @saving="onSaving"
            :allow-column-resizing="false"
            :column-min-width="50"
          >
            <DxPaging :page-size="10" />
            <DxPager
              :show-page-size-selector="true"
              :allowed-page-sizes="pageSizes"
              :show-info="false"
              :show-navigation-buttons="true"
              :visible="true"
            />
            <DxEditing
              mode="batch"
              :allow-adding="false"
              :allow-deleting="false"
              :allow-updating="true"
              class="txtRight"
            />
            <DxSearchPanel :visible="true" :placeholder="placeholder" />

            <!-- <DxColumn data-field="id" :allow-editing="false" /> -->
            <DxColumn
              data-field="studentName"
              :allow-editing="false"
              id="txtRight"
              :caption="$t('name')"
            />
            <DxColumn data-field="extraDegree" :width="100" :caption="$t('degrees.theDegree')">
              <DxRequiredRule :message="$t('degrees.ThisFieldIsRequired')" />
              <DxNumericRule :message="$t('degrees.ItShouldBeANumber')" />
              <DxRangeRule :message="$t('degrees.TheScoreMustBeetween0And100')" max="100" min="0" />
            </DxColumn>
            <DxColumn data-field="notex" :caption="$t('Notes')" />
          </DxDataGrid>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxSearchPanel,
  DxPager,
  DxPaging,
  DxColumnChooser,
  DxLoadPanel,
  DxRequiredRule,
  DxNumericRule,
  DxRangeRule,
} from "devextreme-vue/data-grid";
import "whatwg-fetch";

export default {
  props: ["id"],
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxSearchPanel,
    DxPager,
    DxPaging,
    DxColumnChooser,
    DxLoadPanel,
    DxRequiredRule,
    DxNumericRule,
    DxRangeRule,
  },
  data() {
    return {
      dialog: false,
      placeholder: this.$t('search'),
      rtlEnabled: true,
      showColumnLines: true,
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      overlay: false,
      degree: [],
      dataUpdata: [],
      pageSizes: [10, 15, 20],
    };
  },
  created() {
    this.getDegree();
  },
  methods: {

    onSaving(e) {
      //   this.overlay = true;
      this.dataUpdata = e.changes;
      //   console.log( this.dataUpdata);
      const data = [];
      for (let i = 0; i < this.dataUpdata.length; i++) {
        data.push({
          StudentGuid: this.dataUpdata[i].key.studentGuid,
          Note: this.dataUpdata[i].data.notex,
          Degree: this.dataUpdata[i].data.extraDegree,
        });
        if (!data[i].Degree) {
          data[i].Degree = this.dataUpdata[i].key.extraDegree;
        }
        if (!data[i].Note) {
          data[i].Note = this.dataUpdata[i].key.notex;
        }
      }
      //   console.log(this.dataUpdata);
      // console.log(data);
      this.axios.put(
        "Exams/EditExtraDegrees",
        {
          examGuid: this.id,
          extraDegrees: data,
        }
      )
        .then((res) => {
          this.getDegree();
          this.$swal.fire({
            title: this.$t('operationAccomplishedSuccessfully'),
            text: "",
            icon: "success",
            confirmButtonText: this.$t('close'),
             toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t('AnErrorOccurredDuringTheProcess'),
            text: "",
            icon: "error",
            confirmButtonText: this.$t('close'),
          });
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    getDegree() {
      this.overlay = true;
      this.axios.get(`Exams/GetExtraDegrees?ExamGuid=${this.id}`)
        .then((res) => {
          this.degree = res.data.data;
          // console.log(res);
        })
        .catch(() => {})
        .finally(() => {
          this.overlay = false;
        });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" >
#txtRight {
  text-align: right !important;
}
.dx-datagrid .dx-row > td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
</style>
